<template>
  <div class="outerBox">
    <img src="../../assets/img/title.png" alt="" srcset="">
    <img id="qrcode" src="@/assets/img/download_app.png"/>
    <div class="loginBox">
      <div class="login-tltle">巡查蝠后台管理系统</div>
      <div class="from">
        <el-input class="input_mobile" placeholder="请输入手机号" v-model="formData.mobile" autocomplete name='mobile'>
          <el-select v-model="formData.country_code" slot="prepend" placeholder="请选择" size="mini">
            <el-option label="+86" value="86"></el-option>
            <el-option label="+856" value="856"></el-option>
            <el-option label="+852" value="852"></el-option>
            <el-option label="+886" value="886"></el-option>
          </el-select>
        </el-input>
        <div>
          <el-input
            placeholder="请输入密码"
            prefix-icon="el-icon-lock"
            v-model="formData.password"
            show-password
            style="margin-left:14px"
            @keyup.enter.native="login"
            name='password'
          ></el-input>
          <div class="forget" @click="toForget">忘记密码？</div>
        </div>
        <el-button type="primary" size="medium" @click="login" class="login-button">登录</el-button>
        <div class="register" @click="toRegister">注册账号</div>
      </div>
    </div>
  </div>
</template>

<script>
import { isTelAvailable } from "../../utils/verification";
import { account } from "../../utils/api";
export default {
  name:'login',
  data() {
    return {
      formData: {
        mobile: "",
        country_code: "86",
        password: "",//12345678
        ticket: "",
        randstr: "",
      },
    };
  },

  components: {},
  created() {},
  computed: {},
  mounted() {
    // 初始化腾讯防水墙
    this.captcha = new TencentCaptcha("2091202582", this.validateFu);
  },
  methods: {
    // 点击登录按钮
    async login() {
      // 进行手机号验证
      if (isTelAvailable(this.formData.mobile)) {
        //显示 腾讯防水墙
        this.captcha.show();
      } else {
        this.$message.error("请填写正确的手机号");
      }
    },
    //  防水墙验证后回调函数
    async validateFu({ ret, ticket, randstr }) {
      if (ret === 0) {
        this.formData.ticket = ticket;
        this.formData.randstr = randstr;
        const res = await this.$http.post(account.token, this.formData);
        if(res.code!=0){
          // return this.$message.error('账号或密码错误')
        }
        window.sessionStorage.setItem("token", res.data.token);
        this.getPersonnelInfo();
      }
    },
    // 点击前往忘记密码
    toForget() {
      this.$router.push("forgetPassword");
    },
    // 点击前往注册页面
    toRegister() {
      this.$router.push("register");
    },
    // 获取用户个人信息
    async getPersonnelInfo() {
      const res = await this.$http.get(account.info);
      // res.data.members=[]
      this.$store.commit("setUserInfo", res.data);
      this.$message.success('登录成功')
      if(res.data.members.length>0){
        this.$router.push("projectInfo");
      }else{
        this.$router.push("newRegister");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-input {
  width: 280px;
}
.el-select /deep/ .el-input {
  width: 90px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.login-button {
  width: 280px;
  margin-top: 20px;
}
.outerBox {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: url(../../assets/img/loginbg.jpg);
  background-size: cover;
  background-position: center;
  img{
    position: absolute;
    top: 55%;
    left: 10%;
  }
  #qrcode{
    position: absolute;
    top: 75%;
    left: 10%;
  }
  .loginBox {
    position: absolute;
    width: 350px;
    height: 400px;
    left: 68%;
    top: 15%;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    .login-tltle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 350px;
      height: 55px;
      background: $myColor;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #ffffff;
    }
    .from {
      display: flex;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 300px;
      padding: 0 20px;
      .input_mobile {
        margin: 30px 0;
      }
      .forget {
        // font-size: 12px;
        color: #288eff;
        text-align: right;
        line-height: 30px;
        cursor: pointer;
        display: inline-block;
        margin-left: 238px;
      }
      .register {
        color: #288eff;
        font-size: 18px;
        margin-left: 238px;
        margin-top: 20px;
        cursor: pointer;
        display: inline-block;
      }
    }
  }
}
</style>
